'use client';

import classnames from 'classnames/bind';
import Logo from '../../assets/logos/tilnull-logo.svg';
import styles from './Header.module.scss';
import NavigationMenu, {
  Menu,
} from '../../components/navigationMenu/NavigationMenu';
import Link from 'next/link';
import HamburgerButton from '../../components/hamburgerButton/HamburgerButton';
import { useState } from 'react';

interface HeaderProps {
  menu: Menu;
}

const Header = ({ menu }: HeaderProps) => {
  const componentClass = 'header';
  const cx = classnames.bind(styles);

  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive((current) => !current);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  return (
    <header
      className={cx(`${componentClass}`, {
        [`${componentClass}__menu-active`]: menuActive,
      })}
    >
      <nav className={cx(`${componentClass}`, `${componentClass}__nav`)}>
        <div className={cx(`${componentClass}__logo`)}>
          <Link href="/">
            <Logo
              className="w-20 text-black sm:w-32 lg:w-36"
              alt="logo"
              onClick={closeMenu}
            />
          </Link>
        </div>
        <HamburgerButton open={menuActive} onClick={toggleMenu} />
      </nav>
      <NavigationMenu
        menu={menu}
        menuActive={menuActive}
        closeMenu={closeMenu}
      />
    </header>
  );
};

export default Header;
