import { UrlObject } from 'url';
import Link from 'next/link';

interface IProps {
  id?: string;
  to: UrlObject | string;
  className?: string;
  disabled?: boolean;
  element?: JSX.Element | string | number;
  tooltip?: string;
  onClick?: (data: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const CustomLink = (props: IProps) => {
  const {
    id,
    to = '/',
    className,
    disabled = false,
    element,
    tooltip,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  return (
    <>
      {!disabled && (
        <Link
          id={id}
          href={to}
          title={tooltip}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          className={className}
        >
          {element}
        </Link>
      )}
      {disabled && (
        <div id={id} className={className} title={tooltip}>
          {element}
        </div>
      )}
    </>
  );
};

export default CustomLink;
