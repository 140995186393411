'use client';

import styles from './NavigationMenu.module.scss';
import Icon from '../icon/Icon';
import Text from '../text/Text';
import Link from 'next/link';
import Grid from '../grid/Grid';
import { Theme } from '../../models/Models';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

interface MenuLink {
  _type: 'slug';
  current: string;
}

interface MenuItem {
  _key: string;
  tekst: string;
  lenke: MenuLink;
}

interface MenuSection {
  _key: string;
  name: string;
  lenke: MenuLink;
  menuItems?: MenuItem[] | undefined;
}
export interface Menu {
  navn: string;
  sections: MenuSection[];
}

interface NavigationMenuProps {
  menu: Menu;
  menuActive: boolean;
  closeMenu: () => void;
}

const ESC_KEY_CODE = 'Escape';

const NavigationMenu = ({
  menu,
  menuActive,
  closeMenu,
}: NavigationMenuProps) => {
  const componentClass = 'menu';
  const pathname = usePathname();

  const keyPressHandler = ({ key }: KeyboardEvent) => {
    if (key === ESC_KEY_CODE && menuActive) {
      closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler);
    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuActive]);

  useEffect(() => {
    const mainElement = document.querySelector('main');
    const footerElement = document.querySelector('footer');
    if (!mainElement) {
      return;
    }

    if (menuActive) {
      mainElement.classList.add('menu-hidden');
      footerElement?.classList.add('menu-hidden');
    } else {
      mainElement.classList.remove('menu-hidden');
      footerElement?.classList.remove('menu-hidden');
    }
  }, [menuActive]);

  useEffect(
    () => {
      closeMenu();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  const generateMenuSections = () =>
    menu?.sections?.map((section) => (
      <Grid
        className={styles[`${componentClass}__grid__element`]}
        direction="column"
        gap="small"
        content="flex-start"
        align="flex-start"
        wrap="nowrap"
        key={section._key}
      >
        <Link
          className={styles[`${componentClass}__arrow-button`]}
          href={section.lenke.current}
        >
          <Text
            className={styles[`${componentClass}__arrow-button__text`]}
            size="large"
            type="h3"
            theme={Theme.Dark}
            text={section.name}
            underline={pathname !== section.lenke.current}
            hover
          />
        </Link>
        <ul>
          {section.menuItems &&
            section.menuItems.map((menuItem) => (
              <li key={menuItem._key}>
                <Link
                  href={menuItem.lenke.current}
                  className={styles[`${componentClass}__grid__element__item`]}
                >
                  <Text type="p" text={menuItem.tekst} theme={Theme.Dark} />
                  <Icon
                    className={
                      styles[`${componentClass}__arrow-button__circle-graphic`]
                    }
                    icon="arrow"
                    rotation={0}
                    size={2}
                  />
                </Link>
              </li>
            ))}
        </ul>
      </Grid>
    ));

  return (
    <nav className={menuActive ? styles[`${componentClass}`] : styles.hidden}>
      <section className={styles[`${componentClass}__grid`]}>
        {generateMenuSections()}
      </section>
    </nav>
  );
};

export default NavigationMenu;
