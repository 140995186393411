import classnames from 'classnames/bind';
import styles from './HamburgerButton.module.scss';
import Button from '../buttons/Button';
import Text from '../text/Text';
import { Theme } from '../../models/Models';

interface HamburgerButtonProps {
  open: boolean;
  openText?: string;
  closeText?: string;
  openAriaLabel?: string;
  closeAriaLabel?: string;
  onClick: () => void;
  children?: React.ReactNode;
}

const HamburgerButton = ({
  open,
  openText = 'Meny',
  closeText = 'Lukk',
  openAriaLabel = 'Åpne meny',
  closeAriaLabel = 'Lukk meny',
  onClick,
  children,
}: HamburgerButtonProps) => {
  const componentClass = 'hamburger-button';
  const cx = classnames.bind(styles);
  return (
    <div className={cx(`${componentClass}__button${open ? '__open' : ''}`)}>
      <Button
        type="icon"
        aria-label={open ? closeAriaLabel : openAriaLabel}
        className={cx(`${componentClass}__button__button`)}
        theme={Theme.Dark}
        onClick={onClick}
      >
        <>
          <Text
            className={cx(`${componentClass}__button__text`)}
            text={open ? closeText : openText}
            type="h4"
            theme={Theme.Dark}
          />

          <span className={cx(`${componentClass}__button__icon`)}>
            <div
              className={cx(
                `${componentClass}__button__bar${open ? '__top' : '__first'}`
              )}
            />
            <div
              className={cx(
                `${componentClass}__button__bar${open ? '__mid' : '__second'}`
              )}
            />
            <div
              className={cx(
                `${componentClass}__button__bar${open ? '__bottom' : ''}`
              )}
            />
          </span>
        </>
      </Button>
      {children}
    </div>
  );
};

export default HamburgerButton;
