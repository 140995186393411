import(/* webpackMode: "eager" */ "/vercel/path0/app/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Libre_Franklin\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-libre-franklin\",\"display\":\"swap\"}],\"variableName\":\"libreFranklin\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/agriculture.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/alert-circle-outline.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/arrow-big.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/aviation.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/bar.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/beer-mug.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/beltebil-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/bikes.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/bus-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/bus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/chevron.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/agder.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/akershus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/buskerud.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/finnmark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/innlandet.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/more-og-romsdal.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/nordland.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/norge.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/oslo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/ostfold.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/rogaland.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/telemark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/troms.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/trondelag.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/vestfold.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/countyIcons/vestland.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/crystals.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/delivery-truck.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/electric-plug.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/energy-solar.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/energy.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/equipment-cement.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/external-link.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/fossil-energy-barrel.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/hamburger.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/heating.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/heavy-transport.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/houses.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/industry.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/information.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/lab-flask.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/moped-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/motor-bike-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/motorredskap-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/oil-and-gas.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/other-light-vehicles.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/other-mobile-combustion.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/other-transport.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/other.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/road-traffic.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/send.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/service.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/shipping-and-fishing.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/snowmobile-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/thermometer.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/tractor-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/tractor.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/transport-ship.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/transport.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/tree-log.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/trees-camp-forest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/triangle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/truck-animal.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/truck-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/truck.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/van-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/waste.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/logos/tilnull-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/models/common/spacing.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/index.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/grid/Grid.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/text/Text.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icon/Icon.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/mailTo/MailTo.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/feedback/Feedback.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/header/Header.tsx");
