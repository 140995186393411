import classnames from 'classnames/bind';
import { icons } from '../../models/Models';

import Agriculture from '../../assets/icons/agriculture.svg';
import Alert from '../../assets/icons/alert-circle-outline.svg';
import ExternalLink from '../../assets/icons/external-link.svg';
import Arrow from '../../assets/icons/arrow.svg';
import ArrowBig from '../../assets/icons/arrow-big.svg';
import AviationTransport from '../../assets/icons/aviation.svg';
import BeerMug from '../../assets/icons/beer-mug.svg';
import Bus from '../../assets/icons/bus.svg';
import BusSide from '../../assets/icons/bus-side.svg';
// Common Icons
import Chevron from '../../assets/icons/chevron.svg';
import Close from '../../assets/icons/close.svg';
import Crystal from '../../assets/icons/crystals.svg';
import DeliveryTruck from '../../assets/icons/delivery-truck.svg';
import ElectricPlug from '../../assets/icons/electric-plug.svg';
import Energy from '../../assets/icons/energy.svg';
import EquipmentCement from '../../assets/icons/equipment-cement.svg';
import FossilEnergyBarrel from '../../assets/icons/fossil-energy-barrel.svg';
import Heating from '../../assets/icons/heating.svg';
import Industry from '../../assets/icons/industry.svg';
import Information from '../../assets/icons/information.svg';
import LabFlask from '../../assets/icons/lab-flask.svg';
import MobileCombustion from '../../assets/icons/other-mobile-combustion.svg';
import MopedSide from '../../assets/icons/moped-side.svg';
import MotorbikeSide from '../../assets/icons/motor-bike-side.svg';
import OilAndGas from '../../assets/icons/oil-and-gas.svg';
import Other from '../../assets/icons/other.svg';
import OtherTransport from '../../assets/icons/other-transport.svg';
import RoadsTransport from '../../assets/icons/road-traffic.svg';
import RoadsTransportBikes from '../../assets/icons/bikes.svg';
import RoadsTransportHeavy from '../../assets/icons/heavy-transport.svg';
import RoadsTransportLight from '../../assets/icons/other-light-vehicles.svg';
import SeaTransport from '../../assets/icons/shipping-and-fishing.svg';
import Send from '../../assets/icons/send.svg';
import Thermometer from '../../assets/icons/thermometer.svg';
import Tractor from '../../assets/icons/tractor.svg';
import TractorSide from '../../assets/icons/tractor-side.svg';
import TransportShip from '../../assets/icons/transport-ship.svg';
import TreesCampForest from '../../assets/icons/trees-camp-forest.svg';
import Motorredskap from '../../assets/icons/motorredskap-side.svg';
import Beltebil from '../../assets/icons/beltebil-side.svg';
import Houses from '../../assets/icons/houses.svg';
import SnowmobileSide from '../../assets/icons/snowmobile-side.svg';
// Categories
import Transport from '../../assets/icons/transport.svg';
import TreeLog from '../../assets/icons/tree-log.svg';
import Triangle from '../../assets/icons/triangle.svg';
import Truck from '../../assets/icons/truck.svg';
import TruckAnimal from '../../assets/icons/truck-animal.svg';
import TruckSide from '../../assets/icons/truck-side.svg';
import VanSide from '../../assets/icons/van-side.svg';
import Waste from '../../assets/icons/waste.svg';
import Hamburger from '../../assets/icons/hamburger.svg';
import EnergySolar from '../../assets/icons/energy-solar.svg';
import Service from '../../assets/icons/service.svg';
import styles from './Icon.module.scss';
// Hamburger
import Bar from '../../assets/icons/bar.svg';
// Counties
import Agder from '../../assets/icons/countyIcons/agder.svg';
import Akershus from '../../assets/icons/countyIcons/akershus.svg';
import Buskerud from '../../assets/icons/countyIcons/buskerud.svg';
import Finnmark from '../../assets/icons/countyIcons/finnmark.svg';
import Innlandet from '../../assets/icons/countyIcons/innlandet.svg';
import MoreOgRomsdal from '../../assets/icons/countyIcons/more-og-romsdal.svg';
import Nordland from '../../assets/icons/countyIcons/nordland.svg';
import Oslo from '../../assets/icons/countyIcons/oslo.svg';
import Ostfold from '../../assets/icons/countyIcons/ostfold.svg';
import Rogaland from '../../assets/icons/countyIcons/rogaland.svg';
import Telemark from '../../assets/icons/countyIcons/telemark.svg';
import Troms from '../../assets/icons/countyIcons/troms.svg';
import Trondelag from '../../assets/icons/countyIcons/trondelag.svg';
import Vestfold from '../../assets/icons/countyIcons/vestfold.svg';
import Vestland from '../../assets/icons/countyIcons/vestland.svg';
import Norge from '../../assets/icons/countyIcons/norge.svg';

interface IIcon {
  id?: string;
  className?: string;
  icon?: icons;
  size?: number | string;
  height?: number | boolean;
  rotation?: 0 | 45 | 90 | 180 | -45 | -90 | -180;
}

/**
 * Icon
 * @description is a icon component used to allow
 * for more svg customization and editing.
 * @param IProps props
 *  @param string props.id
 *  @param string props.className
 *  @param icons props.icon
 *  @param colors props.color (Default: "black")
 *  @param colors props.useFull (Default: false)
 *  @param colors props.size (Default: "auto")
 *  @param number props.rotation (Default: 0)
 * @return Icon
 */
const Icon = (props: IIcon) => {
  const {
    id,
    className,
    rotation = 0,
    size = 'auto',
    height = false,
    icon = 'other',
  } = props;

  // ************************************
  // Properties
  // ************************************

  const componentClass = 'icon';
  const cx = classnames.bind(styles);

  // ************************************
  // Icon Fetching Functionality
  // ************************************

  const getIconSource = (iconValue: icons) => {
    switch (iconValue) {
      case 'chevron':
        return Chevron;
      case 'arrow':
        return Arrow;
      case 'arrow-big':
        return ArrowBig;
      case 'triangle':
        return Triangle;
      case 'close':
        return Close;
      case 'information':
        return Information;
      case 'alert-circle-outline':
        return Alert;
      case 'transport':
        return Transport;
      case 'bikes':
        return RoadsTransportBikes;
      case 'heavy-transport':
        return RoadsTransportHeavy;
      case 'other-light-vehicles':
        return RoadsTransportLight;
      case 'oil-and-gas':
        return OilAndGas;
      case 'industry':
        return Industry;
      case 'heating':
        return Heating;
      case 'road-traffic':
        return RoadsTransport;
      case 'aviation':
        return AviationTransport;
      case 'shipping-and-fishing':
        return SeaTransport;
      case 'send':
        return Send;
      case 'other-transport':
        return OtherTransport;
      case 'agriculture':
        return Agriculture;
      case 'bus':
        return Bus;
      case 'electric-plug':
        return ElectricPlug;
      case 'truck':
        return Truck;
      case 'waste':
        return Waste;
      case 'other-mobile-combustion':
        return MobileCombustion;
      case 'energy':
        return Energy;
      case 'tractor':
        return Tractor;
      case 'thermometer':
        return Thermometer;
      case 'beer-mug':
        return BeerMug;
      case 'equipment-cement':
        return EquipmentCement;
      case 'fossil-energy-barrel':
        return FossilEnergyBarrel;
      case 'tree-log':
        return TreeLog;
      case 'crystals':
        return Crystal;
      case 'lab-flask':
        return LabFlask;
      case 'truck-animal':
        return TruckAnimal;
      case 'delivery-truck':
        return DeliveryTruck;
      case 'truck-side':
        return TruckSide;
      case 'tractor-side':
        return TractorSide;
      case 'motor-bike-side':
        return MotorbikeSide;
      case 'moped-side':
        return MopedSide;
      case 'van-side':
        return VanSide;
      case 'bus-side':
        return BusSide;
      case 'motorredskap-side':
        return Motorredskap;
      case 'beltebil-side':
        return Beltebil;
      case 'snowmobile-side':
        return SnowmobileSide;
      case 'transport-ship':
        return TransportShip;
      case 'trees-camp-forest':
        return TreesCampForest;
      case 'hamburger':
        return Hamburger;
      case 'bar':
        return Bar;
      case 'energy-solar':
        return EnergySolar;
      case 'houses':
        return Houses;
      case 'service':
        return Service;
      case 'other':
        return Other;
      case 'external-link':
        return ExternalLink;
      case 'Agder':
        return Agder;
      case 'Akershus':
        return Akershus;
      case 'Buskerud':
        return Buskerud;
      case 'Finnmark':
        return Finnmark;
      case 'Innlandet':
        return Innlandet;
      case 'Møre og Romsdal':
        return MoreOgRomsdal;
      case 'Nordland':
        return Nordland;
      case 'Oslo':
        return Oslo;
      case 'Østfold':
        return Ostfold;
      case 'Rogaland':
        return Rogaland;
      case 'Telemark':
        return Telemark;
      case 'Troms':
        return Troms;
      case 'Trøndelag':
        return Trondelag;
      case 'Vestfold':
        return Vestfold;
      case 'Vestland':
        return Vestland;
      case 'Hele landet':
        return Norge;
      default:
        return Other;
    }
  };

  // ************************************
  // Helper Functionality
  // ************************************

  const getSize = () => {
    if (size === 'auto') {
      return size;
    }
    return `${size}rem`;
  };

  const IconSource = getIconSource(icon);

  return (
    <IconSource
      id={id}
      className={cx(componentClass, {
        [`${className}`]: className,
      })}
      style={{
        width: `${getSize()}`,
        height: `${height || getSize()}`,
        transform: `rotate(${rotation}deg)`,
      }}
      alt={icon}
    />
  );
};

export default Icon;
